import Vue from 'vue';
import Router from 'vue-router';
// import Starter from './pages/StarterPage.vue';
import Landing from './pages/Landing.vue';
// import StarterNavbar from './layout/StarterNavbar.vue';
// import StarterFooter from './layout/StarterFooter.vue';
import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';
Vue.use(Router);

export default new Router({
  mode:'history',
  routes: [
    {
      path: '/',
      name: 'index',
      components: {
        default: Landing,
        header: MainNavbar,
        footer: MainFooter
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: 'black' }
      }
    },
    {
      path:'/gps',
      beforeEnter(to,from,next){
        // console.log('redirecting' ,to)
        window.location.href = 'https://gps.transportglobalsecurity.com';
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
